var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"cms-create-referral","title":"New Referral","no-close-on-backdrop":"","size":"lg"},on:{"ok":_vm.onOk}},[_c('ValidationObserver',{ref:"cmsForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"title","rules":{
          required: false,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title"}},[_c('b-form-input',{attrs:{"id":"title","state":errors[0] ? false : null},model:{value:(_vm.createReferralForm.title),callback:function ($$v) {_vm.$set(_vm.createReferralForm, "title", $$v)},expression:"createReferralForm.title"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"image_src","rules":{
          required: false,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Image"}},[_c('CmsFileSelect',{attrs:{"value":_vm.createReferralForm.imageSrc,"state":errors[0] ? false : null},on:{"update":function (value) { return (_vm.createReferralForm.imageSrc = value || ''); }}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"content","rules":{
          required: false,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Content"}},[_c('vue-editor',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.createReferralForm.content),callback:function ($$v) {_vm.$set(_vm.createReferralForm, "content", $$v)},expression:"createReferralForm.content"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"position","rules":{
          required: true,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Position"}},[_c('b-form-input',{attrs:{"id":"position","state":errors[0] ? false : null},model:{value:(_vm.createReferralForm.position),callback:function ($$v) {_vm.$set(_vm.createReferralForm, "position", $$v)},expression:"createReferralForm.position"}})],1)]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }